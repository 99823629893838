import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouterPaths } from "./paths";
import { RouterPathName } from "./config";
import Loader from "../components/loader/Loader";

function RouterApp() {
  return (
    <div>
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path={RouterPathName.home} element={<RouterPaths.Home />} />
            <Route
              path={RouterPathName.termsConditions}
              element={<RouterPaths.TermsAndConditions />}
            />
            <Route path={RouterPathName.privacyPolicy}
              element={<RouterPaths.PrivacyPolicy />} />
            <Route
              path={RouterPathName.notfound}
              element={<RouterPaths.NotFound />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default RouterApp;
