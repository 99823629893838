import './App.scss';
import RouterApp from './routes/router';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const  App = ()=> {
  return (
    <div className="App">
      <RouterApp />
    </div>
  );
}

export default App;
